import logo from "../logo.svg";
import background from "../img/Rectangle_113.png";
import "../App.css";
import Image from "react-bootstrap/Image";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { Component, useState, Linking } from "react";
import config from "../config/config";
import ReactDOM from "react-dom";
import { QRCodeSVG } from "qrcode.react";
import Modal from "react-bootstrap/Modal";
const baseURL = config.base_url + "company/detail/";
const img_url = config.base_img_url;
const baseImgUrl = config.base_url + "banner";
function ServiceDetail(props) {
  const baseUrl = config.base_url;
  console.log("props", props);
  var s_id = localStorage.getItem("serviceDetailID");
  console.log("serviceDetailID", localStorage.getItem("serviceDetailID"));
  const [companyDetail, setCompanyDetail] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [bannerImg, setBannerImg] = React.useState([]);
  React.useEffect(() => {
    axios.get(baseURL + s_id).then((response) => {
      setCompanyDetail(response.data);
      setIsReady(true);
      console.log("response", response.data);
      console.log("response name", response.data.name);
    });
    axios.get(baseImgUrl).then((response) => {
      setBanner(response.data);
      setBannerImg(response.data[1].value);
      setIsReady(true);
      console.log("banner", response.data);
    });
  }, []);
  const imgUrl = (url) => {
    return img_url + url;
  };
  const tel_num = (ph_num) => {
    return "tel:" + ph_num;
  };
  const email_address = (email_add) => {
    return "mailto:" + email_add;
  };
  let navigate = useNavigate();
  const mapClick = () => {
    console.log("map");
    navigate("/map", this);
  };
  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    return invokeModal(!false);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Description = (des) => {
    const regEx = /(<([^>]+)>)/gi;
    const tempResult = des.replace(regEx, "");
    const secondRegEx = /((&nbsp;))*/gim;
    const description = tempResult.replace(secondRegEx, "");
    return description;
  };
  return (
    <div>
      <div class="col-12">
        <div
          class="text-light d-flex justify-content-between px-3 py-2"
          style={{ height: 50, backgroundColor: "#2D3134" }}
        >
          <Link to="/servicelist">
            <i class="bi bi-chevron-left text-light"></i>
          </Link>
          <span>Security Service Detail</span>
          <i class=""></i>
        </div>
      </div>
      <div class="col-12">
        <img src={imgUrl(bannerImg)} class="w-100" height="150" />
      </div>

      <div>
        {companyDetail ? (
          <div class="container mb-3">
            <div class="row">
              <div class="col-12">
                <Modal show={show} onHide={handleClose} centered>
                  <QRCodeSVG
                    id=""
                    value={
                      "Name:" +
                      " " +
                      companyDetail.name +
                      "\n \n" +
                      "Address:" +
                      " " +
                      companyDetail.address +
                      "\n \n" +
                      "Location:" +
                      " " +
                      companyDetail.location +
                      "\n \n" +
                      "Phone:" +
                      " " +
                      companyDetail.phone_1 +
                      "\n \n" +
                      "Email:" +
                      " " +
                      companyDetail.email +
                      "\n \n" +
                      "Website:" +
                      " " +
                      companyDetail.website
                    }
                    style={{ width: "100%", height: 350 }}
                    class="p-3"
                  />
                </Modal>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div
                  class="mx-auto"
                  style={{
                    border: "7px solid #FFBC00",
                    marginTop: "-45px",
                    width: "30%",
                  }}
                >
                  <img
                    src={imgUrl(companyDetail.logo)}
                    class="w-100"
                    height="90"
                  />
                </div>
                <h5 class="text-center mt-3">{companyDetail.name}</h5>
              </div>
            </div>
            <div class="row g-2 px-2 my-3">
              <div class="col">
                <div
                  class="card text-center py-2 rounded-3"
                  style={{ backgroundColor: "#FFFDD0", border: "none" }}
                >
                  <a
                    href={tel_num(companyDetail.phone_1)}
                    class="text-decortion-none"
                  >
                    <i
                      class="bi bi-telephone-inbound-fill text-black"
                      style={{ fontSize: "25px" }}
                    ></i>
                  </a>
                </div>
              </div>
              <div class="col">
                <div
                  class="card text-center py-2 rounded-3"
                  style={{ backgroundColor: "#FFFDD0", border: "none" }}
                  onClick={mapClick}
                >
                  <i
                    class="bi bi-geo-alt-fill"
                    style={{ color: "#D51616", fontSize: "25px" }}
                  ></i>
                </div>
              </div>
              <div class="col">
                <div
                  class="card text-center py-2 rounded-3"
                  style={{ backgroundColor: "#FFFDD0", border: "none" }}
                >
                  <a href={companyDetail.facebook} class="text-decortion-none">
                    <i class="bi bi-facebook" style={{ fontSize: "25px" }}></i>
                  </a>
                </div>
              </div>
              <div class="col">
                <div
                  class="card text-center py-2 rounded-3"
                  style={{ backgroundColor: "#FFFDD0", border: "none" }}
                >
                  <a
                    href={email_address(companyDetail.email)}
                    class="text-decortion-none"
                  >
                    <i
                      class="bi bi-envelope-open-fill"
                      style={{ color: "#274472", fontSize: "25px" }}
                    ></i>
                  </a>
                </div>
              </div>
              <div class="col">
                <div
                  class="card text-center py-2 rounded-3"
                  style={{ backgroundColor: "#FFFDD0", border: "none" }}
                  onClick={handleShow}
                >
                  <i
                    class="bi bi-qr-code"
                    style={{ color: "#FFBC00", fontSize: "25px" }}
                  ></i>
                </div>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-12">
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFFDD0",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <h5 class="pt-3 px-3">
                    {companyDetail.location} Head Office
                  </h5>
                  <small class="px-3 py-2">{companyDetail.address}</small>
                  <div class="d-flex justify-content-between my-3">
                    <div class="d-flex ms-2 mt-2">
                      <div
                        class="bg-black rounded-circle d-flex align-items-center justify-content-center me-2"
                        style={{
                          width: 30,
                          height: 30,
                          fontSize: 12,
                          color: "#FFFFFF",
                        }}
                      >
                        <i class="bi bi-telephone-inbound-fill"></i>
                      </div>
                      <a
                        href={tel_num(companyDetail.phone_1)}
                        class="text-decoration-none text-black"
                      >
                        {companyDetail.phone_1}
                      </a>
                    </div>
                    {companyDetail.phone_2 != null ? (
                      <div class="d-flex me-2 mt-2">
                        <div
                          class="bg-black rounded-circle d-flex align-items-center justify-content-center me-2"
                          style={{
                            width: 30,
                            height: 30,
                            fontSize: 12,
                            color: "#FFFFFF",
                          }}
                        >
                          <i class="bi bi-telephone-inbound-fill"></i>
                        </div>
                        <a
                          href={tel_num(companyDetail.phone_2)}
                          class="text-decoration-none text-black"
                        >
                          {companyDetail.phone_1}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFFDD0",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <h5 class="pt-3 px-3">Contact Us</h5>

                  <div class="d-flex ms-3 mt-2">
                    <div
                      class="bg-danger rounded-circle d-flex align-items-center justify-content-center me-3"
                      style={{
                        width: 30,
                        height: 30,
                        fontSize: 13,
                        color: "#FFFFFF",
                      }}
                    >
                      <i class="bi bi-envelope-open-fill"></i>
                    </div>
                    <a
                      href={email_address(companyDetail.email)}
                      class="text-decoration-none text-black"
                    >
                      {companyDetail.email}
                    </a>
                  </div>
                  <div class="d-flex ms-3 my-2">
                    <div
                      class="bg-black rounded-circle d-flex align-items-center justify-content-center me-3"
                      style={{
                        width: 30,
                        height: 30,
                        fontSize: 13,
                        color: "#FFFFFF",
                      }}
                    >
                      <i class="bi bi-globe2"></i>
                    </div>
                    <a
                      href={companyDetail.website}
                      class="text-decoration-none text-black"
                    >
                      {companyDetail.website}
                    </a>
                  </div>
                </div>
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFFDD0",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <h5 class="pt-3 px-3">Open Hour</h5>
                  <div class="d-flex ms-3 my-2">
                    <div
                      class="rounded-circle d-flex align-items-center justify-content-center me-3"
                      style={{
                        width: 30,
                        height: 30,
                        fontSize: 13,
                        color: "#FFFFFF",
                        backgroundColor: "#FFBC00",
                      }}
                    >
                      <i class="bi bi-calendar-week"></i>
                    </div>
                    <small>{companyDetail.open_date}</small>
                    <small class="ms-2">({companyDetail.open_time})</small>
                  </div>
                </div>
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFBC00",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <h5 class="pt-3 px-3">Description</h5>
                  <small class="px-3 py-2 mb-1">
                    {Description(companyDetail.description)}
                  </small>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> - </p>
          </div>
        )}
      </div>
    </div>
  );
}
export default ServiceDetail;
