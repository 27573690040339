import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config/config";
const baseURL = config.base_url + "job/detail/";
const img_url = config.base_img_url;
const baseImgUrl = config.base_url + "banner";
function JobDetail(props) {
  console.log("props", props);
  var d_id = localStorage.getItem("jobDetailID");
  console.log("jobDetailID", localStorage.getItem("jobDetailID"));
  const [jobDetail, setJobDetail] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [bannerImg, setBannerImg] = React.useState([]);
  React.useEffect(() => {
    axios.get(baseURL + d_id).then((response) => {
      setJobDetail(response.data);
      setIsReady(true);
      console.log("response", response.data);
    });
    axios.get(baseImgUrl).then((response) => {
      setBanner(response.data);
      setBannerImg(response.data[7].value);
      setIsReady(true);
      console.log("banner", response.data);
    });
  }, []);
  const email_address = (email_add) => {
    return "mailto:" + email_add;
  };
  const imgUrl = (img) => {
    return img_url + img;
  };
  const Description = (des) => {
    const regEx = /(<([^>]+)>)/gi;
    const tempResult = des.replace(regEx, "");
    const secondRegEx = /((&nbsp;))*/gim;
    const description = tempResult.replace(secondRegEx, "");
    return description;
  };
  let navigate = useNavigate();
  const handleClick = (s_id) => {
    console.log("s id", s_id);
    localStorage.setItem("serviceDetailID", s_id);
    navigate("/servicedetail", this);
  };
  if (!isReady) {
    return "";
  }
  return (
    <div>
      <div class="col-12 position-relative">
        <Link to="/joblist">
          <div
            class="position-absolute bg-white rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: 40,
              height: 40,
              fontSize: 20,
              color: "#FFBC00",
              top: 20,
              left: 20,
            }}
          >
            <i class="bi bi-chevron-left"></i>
          </div>
        </Link>
        <img src={imgUrl(bannerImg)} class="w-100" height="200" />
      </div>

      <div>
        {jobDetail ? (
          <div class="container">
            <div class="row" style={{ marginTop: "-10%" }}>
              <div class="col-12">
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFFDD0",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <h5 class="pt-3 px-3">{jobDetail.job_function}</h5>
                  <small
                    class="px-3 py-2"
                    style={{ color: "#717DA3" }}
                    onClick={() => handleClick(jobDetail.company.id)}
                  >
                    <i
                      class="bi bi-signpost-2-fill me-3"
                      style={{ color: "#000000" }}
                    ></i>
                    {jobDetail.company.name}
                  </small>
                  <div class="d-flex justify-content-between">
                    <small class="px-3 py-2 mb-3">
                      <i class="bi bi-geo-alt-fill me-3"></i>Myanmar-
                      {jobDetail.company.location}
                    </small>
                    <small class="px-3 py-2 mb-3">
                      <i class="bi bi-calendar3-week me-2"></i>
                      {jobDetail.date_time}
                    </small>
                  </div>
                </div>
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFFDD0",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <h5 class="pt-3 px-3 pb-2" style={{ color: "#FFBC00" }}>
                    Job Description
                  </h5>
                  <div class="px-3">
                    <p>{Description(jobDetail.description)}</p>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div
                  class="card mb-2"
                  style={{
                    backgroundColor: "#FFFDD0",
                    borderRadius: "1rem",
                    border: "none",
                  }}
                >
                  <p class="mt-3 ms-3">Email: {jobDetail.company.email}</p>
                  <p class="ms-3">Phone: {jobDetail.company.phone_1}</p>
                </div>
              </div>
              <div class="col-12">
                <div class="d-flex justify-content-end mb-5 mt-2">
                  <a
                    href={email_address(jobDetail.company.email)}
                    class="btn rounded-pill px-3"
                    style={{
                      backgroundColor: "#717DA3",
                      color: "#FFFFFF",
                      width: "40%",
                    }}
                  >
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p> - </p>{" "}
          </div>
        )}
      </div>
    </div>
  );
}
export default JobDetail;
