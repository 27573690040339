import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import React, { Component, useState } from "react";
import config from "../config/config";
const url = config.base_url + "company"; //for companylist
const banner_url = config.base_url + "banner";
const img_url = config.base_img_url;
function ServiceList(props) {
  var locat = localStorage.getItem("location");
  console.log("location", localStorage.getItem("location"));
  console.log("location", locat);
  const [company, setCompany] = React.useState(null); //for companylist
  const [companyImg, setCompanyImg] = React.useState(null); //for company image
  const [search, setSearch] = React.useState();
  const [isReady, setIsReady] = React.useState(false);
  const [banner, setBanner] = React.useState(null);
  const [bannerImg4, setBannerImg4] = React.useState([]);
  const [bannerImg3, setBannerImg3] = React.useState([]);
  React.useEffect(() => {
    axios.get(banner_url).then((response) => {
      setBanner(response.data);
      setBannerImg4(response.data[5].value);
      setBannerImg3(response.data[4].value);
      setIsReady(true);
      console.log("banner", response.data);
    });

    axios.get(url + "?location=" + locat).then((response) => {
      setCompany(response.data);
      setCompanyImg(response.data);
      setIsReady(true);
      console.log("companylist", response.data);
    });
  }, []);
  const SearchClick = () => {
    console.log("search", search);
    axios
      .get(url + "/search?location=" + locat + "&name=" + search)
      .then((response) => {
        setCompany(response.data);
      });
  };
  let navigate = useNavigate();
  const handleClick = (s_id) => {
    console.log("s id", s_id);
    localStorage.setItem("serviceDetailID", s_id);
    navigate("/servicedetail", this);
  };
  const handleClickLoc = (loc) => {
    console.log("location :: " + loc);
    localStorage.setItem("location", loc);
    axios.get(url + "?location=" + loc).then((response) => {
      setCompany(response.data);
      setCompanyImg(response.data);
    });
  };
  const imgUrl = (img) => {
    return img_url + img;
  };
  var ii = 0;
  const countPlus = () => {
    ii++;
  };
  if (!isReady) {
    return "";
  }
  var settings = {
    dots: false,
    infinite: ii > 5 ? true : false,
    speed: 500,
    slidesToShow: 5,
    arrows: false,
    slidesToScroll: 5,
  };
  var int = 1;
  return (
    <div>
      <div class="col-12">
        <div
          class="text-light d-flex justify-content-between px-3 py-2"
          style={{ height: 50, backgroundColor: "#2D3134" }}
        >
          <Link to="/home">
            <i class="bi bi-chevron-left text-light"></i>
          </Link>
          {locat == "Mandalay" ? <span>Mandalay</span> : <span>Yangon</span>}
          <i class=""></i>
        </div>
        <div class="col-12">
          {locat == "Mandalay" ? (
            <img src={imgUrl(bannerImg4)} class="w-100" height="150" />
          ) : (
            <img src={imgUrl(bannerImg3)} class="w-100" height="150" />
          )}
        </div>
        <div class="container mb-5">
          <button
            type="button"
            class="btn"
            style={
              locat == "Yangon"
                ? {
                    backgroundColor: "#2D3134",
                    color: "#FFBC00",
                    marginTop: "2rem",
                    marginRight: "1rem",
                    borderRadius: "4rem",
                    width: "45%",
                  }
                : {
                    marginTop: "2rem",
                    marginRight: "1rem",
                    borderRadius: "4rem",
                    width: "45%",
                    backgroundColor: "#F2F1F0",
                  }
            }
            onClick={() => handleClickLoc("Yangon")}
          >
            Lower Myanmar
          </button>
          <button
            type="button"
            class="btn"
            style={
              locat == "Mandalay"
                ? {
                    backgroundColor: "#2D3134",
                    color: "#FFBC00",
                    marginTop: "2rem",
                    marginLeft: "1rem",
                    borderRadius: "4rem",
                    width: "45%",
                  }
                : {
                    marginTop: "2rem",
                    marginLeft: "1rem",
                    borderRadius: "4rem",
                    width: "45%",
                    backgroundColor: "#F2F1F0",
                  }
            }
            onClick={() => handleClickLoc("Mandalay")}
          >
            Upper Myanmar
          </button>
          {companyImg
            ? companyImg.map((company) => {
                if (company.is_vip == 1 && int) {
                  int = 0;
                  return (
                    <h3
                      style={{
                        fontWeight: "bold",
                        marginTop: "2rem",
                        marginBottom: "1rem",
                      }}
                    >
                      Company Logo
                    </h3>
                  );
                }
              })
            : ""}
          <div class="row ">
            <div class="col-12">
              <Slider {...settings}>
                {companyImg ? (
                  companyImg.map((company) => {
                    if (company.is_vip == 1) {
                      countPlus();
                      return (
                        <div
                          class="p-1 shadow-sm"
                          onClick={() => handleClick(company.id)}
                        >
                          <img
                            class="rounded-3"
                            src={imgUrl(company.logo)}
                            width="100%"
                            height="60"
                          />
                        </div>
                      );
                    }
                  })
                ) : (
                  <div>
                    <p> - </p>
                  </div>
                )}
              </Slider>
            </div>
          </div>
          <div class="row height d-flex justify-content-center align-items-center mt-3">
            <div class="col-md-8">
              <div class="search mt-2" style={{ position: "relative" }}>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Find the company name here."
                  style={{
                    height: "50px",
                    textIndent: "25px",
                    borderRadius: "1rem",
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <i
                  class="bi bi-search"
                  style={{
                    position: "absolute",
                    top: "12px",
                    left: "10px",
                    fontSize: "16px",
                  }}
                ></i>
                <button
                  class="btn"
                  style={{
                    position: "absolute",
                    top: "6px",
                    right: "5px",
                    height: "37px",
                    width: "5rem",
                    backgroundColor: "#ffbc00",
                    borderRadius: "1rem",
                  }}
                  onClick={() => SearchClick()}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <h3
            style={{
              fontWeight: "bold",
              marginTop: "2rem",
              marginBottom: "1rem",
            }}
          >
            All Company Lists
          </h3>
          {company ? (
            company.map((company) => {
              if (company.is_vip === 1) {
                return (
                  <div
                    class="card"
                    style={{
                      marginTop: "6px",
                      backgroundColor: "#2d3134",
                      borderRadius: "1rem",
                    }}
                    onClick={() => handleClick(company.id)}
                  >
                    <div
                      class="card-body d-flex justify-content-between"
                      style={{ color: "#f2b303" }}
                    >
                      <div
                        class="bg-white rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          width: 30,
                          height: 30,
                          fontSize: 13,
                          color: "#FFBC00",
                        }}
                      >
                        <i class="bi bi-gem"></i>
                      </div>
                      <p style={{ marginBottom: "0rem" }}>
                        {company.name.substring(0, 25)}...
                      </p>
                      <i class="bi bi-chevron-right"></i>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    variant="primary"
                    onClick={() => handleClick(company.id)}
                    class="card"
                    style={{
                      marginTop: "6px",
                      backgroundColor: "#f6b12b",
                      borderRadius: "1rem",
                    }}
                  >
                    <div class="card-body d-flex justify-content-between">
                      <div
                        class="bg-white rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          width: 30,
                          height: 30,
                          fontSize: 13,
                          color: "#FFBC00",
                        }}
                      >
                        <i class="bi bi-gem"></i>
                      </div>
                      <p style={{ marginBottom: "0rem" }}>
                        {company.name.substring(0, 25)}...
                      </p>
                      <i class="bi bi-chevron-right"></i>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div>
              <p> -</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ServiceList;
