import "../App.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Link } from "react-router-dom";
import axios from "axios";
import React, { Component, useState } from "react";
import config from "../config/config";

const baseURL = config.base_url + "company/detail/";
function Map() {
  var s_id = localStorage.getItem("serviceDetailID");
  console.log("serviceDetailID", localStorage.getItem("serviceDetailID"));
  const [companyDetail, setCompanyDetail] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    axios.get(baseURL + s_id).then((response) => {
      setCompanyDetail(response.data);
      setIsReady(true);
      console.log("response", response.data);
      console.log("response name", response.data.name);
    });
  }, []);
  if (!isReady) {
    return "";
  }
  return (
    <div>
      <div class="col-12">
        <div
          class="text-light d-flex justify-content-between px-3 py-2"
          style={{
            height: 50,
            backgroundColor: "#2D3134",
          }}
        >
          <Link to="/servicedetail">
            <i class="bi bi-chevron-left text-light"></i>
          </Link>
          <span>Map</span>
          <i class=""></i>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <iframe
              src={companyDetail.map}
              width="100%"
              height="700"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Map;
