import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config/config";
const img_url = config.base_url + "ads";
const baseImgUrl = config.base_img_url;
function Ads(props) {
  var locat = localStorage.getItem("location");
  console.log("location", localStorage.getItem("location"));
  const [ads, setAds] = React.useState(null);
  useEffect(() => {
    axios.get(img_url + "?location=" + locat).then((response) => {
      setAds(response.data);
      console.log(response.data);
    });
    const timer = setTimeout(() => {
      navigate("/servicelist");
    }, 7000);
    return () => clearTimeout(timer);
  }, []);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  let navigate = useNavigate();
  var timeleft = 6;
  var downloadTimer = setInterval(function () {
    if (timeleft <= 0) {
      clearInterval(downloadTimer);
      document.getElementById("countdown").innerHTML = "";
    } else {
      document.getElementById("countdown").innerHTML =
        timeleft + " seconds remaining";
    }
    timeleft -= 1;
  }, 1000);
  const imgUrl = (img_url) => {
    return baseImgUrl + "/" + img_url;
  };
  return (
    <div class="container-full">
      <div class="row">
        <div class="col-12">
          <Slider {...settings}>
            {ads ? (
              ads.map((ads) => {
                if (ads.location == locat) {
                  return (
                    <img
                      src={imgUrl(ads.image)}
                      class="w-100 vh-100 position-relative"
                    />
                  );
                }
              })
            ) : (
              <div>
                <p> - </p>
              </div>
            )}
          </Slider>
          <div
            id="countdown"
            class="position-absolute text-bold"
            style={{ top: 10, right: 5 }}
          ></div>
        </div>
      </div>
    </div>
  );
}
export default Ads;
