import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import reportWebVitals from './reportWebVitals'; 
import {   
  BrowserRouter,
  Routes,
  Route,
}
  from "react-router-dom";

  import App from './component/App';
  import Home from './component/Home';
  import ServiceList from './component/ServiceList';
  import JobList from './component/JobList';
  import JobDetail from './component/JobDetail';
  import ServiceDetail from './component/ServiceDetail';
  import About from './component/About';
  import AboutDetail from './component/AboutDetail';
  import Map from './component/Map';
  import Introduction from './component/Introduction';
  import Ads from './component/Ads';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
   <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Introduction />} />
              <Route path="home" element={<Home />} />
              <Route path="serviceList" element={<ServiceList />} />
              <Route path="joblist" element={<JobList />} />
              <Route path="jobdetail" element={<JobDetail />} />
              <Route path="servicedetail" element={<ServiceDetail />} />
              <Route path="about" element={<About />} />
              <Route path="aboutdetail" element={<AboutDetail />} />
              <Route path="map" element={<Map />} />
              <Route path="ads" element={<Ads />} />
      </Routes>
      </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
