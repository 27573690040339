import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
function AboutDetail(props) {
  console.log("props", props);
  return (
    <div>
      <div class="col-12">
        <div
          class="text-light d-flex justify-content-between px-3 py-2"
          style={{ height: 50, backgroundColor: "#2D3134" }}
        >
          <Link to="/about">
            <i class="bi bi-chevron-left text-light"></i>
          </Link>
          <span>About Application</span>
          <i class=""></i>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul
              class="nav nav-pills mb-3 d-flex justify-content-center my-3"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item me-3" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  style={{
                    borderRadius: "2rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  About App
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link "
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  style={{ borderRadius: "2rem" }}
                >
                  Term & Conditions
                </button>
              </li>
            </ul>
            <div class="tab-content mt-4" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="px-2">
                      <h5>About Application</h5>
                      <small>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen
                        book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining
                        essentially unchanged. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutDetail;
