import "../App.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { Component, useState } from "react";
import axios from "axios";
import config from "../config/config";
const baseUrl = config.base_url + "banner";
const img_url = config.base_img_url;

function Home() {
  const [banner, setBanner] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    axios.get(baseUrl).then((response) => {
      setBanner(response.data);
      setIsReady(true);
      console.log("response", response.data);
    });
  }, []);
  let navigate = useNavigate();
  const handleClick = (s_id) => {
    localStorage.setItem("serviceDetailID", s_id);
    navigate("/about", this);
  };
  const handlelocClick = (loc) => {
    localStorage.setItem("location", loc);
    navigate("/ads", this);
  };

  const imgUrl = (url) => {
    console.log("imgurl" + url);
    return img_url + url;
  };
  const slide_Url = (surl) => {
    return img_url + "uploads/banner/" + surl;
  };
  if (!isReady) {
    return "";
  }
  var sliderUrl = JSON.parse(banner[2].value);
  console.log(sliderUrl.url);
  return (
    <div>
      <img
        src={imgUrl(banner[3].value)}
        class="w-100"
        height="150"
        style={{ marginBottom: 12, backgroundColor: "gray" }}
      />

      <div class="container position-relative">
       
        {/* <div class="row my-2">
          <div class="col-12">
            <h5>Directory</h5>
            <small>
              Choose the location to view all of the service companies.
            </small>
          </div>
        </div> */}
        <div class="row mt-2 gx-2">
          <div class="col-12 location_relative">
          <img src={require("../img/location_update.jpg")} class="w-100 " />
          <div class="row location_absolute">
            <p class="text-center location_title">Security Service Area</p>
           
            <div class="col-6 col-md-6 col-lg-6 text-center ">
              <div class="text-center  pb-2 location_text">
                <small><i>Upper Myanmar</i></small>
              </div>
              <div onClick={() => handlelocClick("Yangon")}>
                <button class="btn btn-sm px-2 view_more_btn"><small><i>View More</i></small></button>
              </div>
             
             
            </div>
            <div class="col-6 col-md-6 col-lg-6 text-center ">
              <div class="text-center  pb-2 location_text">
                <small><i>Lower Myanmar</i></small>
              </div>
              <div onClick={() => handlelocClick("Mandalay")}>
                <button class="btn btn-sm px-2 view_more_btn"><small><i>View More</i></small></button>
              </div>
              
              
            </div>
          </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
              {sliderUrl.map((slide) => {
                return (
                  <div>
                    <img src={slide_Url(slide.url)} />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        {/* <div class="row my-2">
          <div class="col-12">
            <h5>Features</h5>
            <small>This is all featured services from SSGM.</small>
          </div>
        </div>  */}
        {/* <div class="row mt-3 gx-2" style={{ marginBottom: 80 }}>
          <div class="col-6">
            <div onClick={() => handlelocClick("Yangon")}>
              <img src={require("../img/service_logo.png")} class="w-100" />
            </div>
          </div>
          <div class="col-6">
            <Link to="/joblist">
              <img src={require("../img/job_logo.png")} class="w-100" />
            </Link>
          </div>
        </div>  */}
        <div  class="row mt-3 gx-2" style={{ marginBottom: 80 }}>
          <div class="col-12 service_relative">
            <img src={require("../img/service.jpg")} class="w-100" />
            <div class="service_absolute">
              <Link to="/joblist">
                <img src={require("../img/job.jpg")} class="service_job" />
              </Link>
            </div>
          </div>
         
        </div>
        
      </div>
      <div class="col-12 position-fixed" style={{ bottom: 0 }}>
        <div
          class="d-flex justify-content-between pt-2 mt-2"
          style={{ height: 60, backgroundColor: "#2D3134" }}
        >
          <Link to="/home">
            <div
              class="ms-5  rounded-circle d-flex align-items-center justify-content-center"
              style={{
                width: 40,
                height: 40,
                fontSize: 20,
                backgroundColor: "#FFBC00",
                color: "#FFFFFF",
              }}
            >
              <i class="bi bi-house-door-fill mb-1"></i>
            </div>
          </Link>
          <Link to="/servicelist">
            <div
              class="bg-white rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: 40, height: 40, fontSize: 20, color: "#FFBC00" }}
            >
              <i class="bi bi-grid-fill"></i>
            </div>
          </Link>
          <Link to="/about">
            <div
              class="me-5 bg-white rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: 40, height: 40, fontSize: 20, color: "#FFBC00" }}
            >
              <i class="bi bi-info-lg"></i>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Home;
