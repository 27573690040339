import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config/config";
import React, { Component, useState } from "react";
const baseUrl = config.base_url + "banner";
const img_url = config.base_img_url;

function Introduction() {
  const [banner, setBanner] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [intCount, setIntCount] = React.useState(0);
  const ref = React.useRef(null);
  React.useEffect(() => {
    axios.get(baseUrl).then((response) => {
      setBanner(response.data);
      setIsReady(true);
      console.log("response", response.data);
    });
  }, []);

  const NextSLide = (props) => {
    console.log("current", ref?.current);
    return (
      <div
        class="rounded-circle d-flex align-items-center justify-content-center"
        onClick={() => ref?.current?.slickNext()}
        style={{
          width: 40,
          height: 40,
          fontSize: 20,
          backgroundColor: "#FFBC00",
          color: "#FFFFFF",
        }}
      >
        <i class="bi bi-chevron-right"></i>
      </div>
    );
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    beforeChange: () => {
      setIntCount(intCount + 1);
    },
  };
  if (!isReady) {
    return "";
  }

  var sliderUrl = JSON.parse(banner[0].value);
  console.log(sliderUrl.url);
  const slide_Url = (surl) => {
    return img_url + "uploads/banner/" + surl;
  };
  return (
    <div>
      <div class="col-12 position-relative">
        <Slider ref={ref} {...settings}>
          {sliderUrl.map((slide) => {
            return (
              <div>
                <img src={slide_Url(slide.url)} class="w-100 vh-100" />
              </div>
            );
          })}
        </Slider>
      </div>
      {intCount == 2 ? (
        <div class="col-12 position-absolute" style={{ bottom: "8%" }}>
          <div class="d-flex justify-content-center px-4">
            <Link to="/home">
              <button class="btn btn-warning">Get Start</button>
            </Link>
          </div>
        </div>
      ) : (
        <div class="col-12 position-absolute" style={{ bottom: "8%" }}>
          <div class="d-flex justify-content-between px-4">
            <Link to="/home" class="text-decoration-none">
              <h4 class="d-flex align-items-center mt-1 text-light justify-content-start">
                Skip
              </h4>
            </Link>

            <NextSLide />
          </div>
        </div>
      )}
    </div>
  );
}
export default Introduction;
