import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React, { Component } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import config from "../config/config";
const url = config.base_url + "job";
const baseImgUrl = config.base_url + "banner";
const img_url = config.base_img_url;
function Joblist() {
  const [job, setJob] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const [isReady, setIsReady] = React.useState(false);
  const [bannerImg, setBannerImg] = React.useState([]);
  React.useEffect(() => {
    axios.get(url).then((response) => {
      setJob(response.data);
      setIsReady(true);
      console.log("response", response.data);
    });
    axios.get(baseImgUrl).then((response) => {
      setBanner(response.data);
      setBannerImg(response.data[6].value);
      setIsReady(true);
      console.log("banner", response.data);
    });
  }, []);
  let navigate = useNavigate();
  const handleClick = (j_id) => {
    console.log("j id", j_id);
    localStorage.setItem("jobDetailID", j_id);
    navigate("/jobdetail", this);
  };
  const imgUrl = (img) => {
    return img_url + img;
  };
  const Description = (des) => {
    const regEx = /(<([^>]+)>)/gi;
    const tempResult = des.replace(regEx, "");
    const secondRegEx = /((&nbsp;))*/gim;
    const description = tempResult.replace(secondRegEx, "");
    return description;
  };

  if (!isReady) {
    return "";
  }
  return (
    <div>
      <div class="position-relative col-12 d-flex justify-content-center align-items-center">
        <Link to="/home">
          <div
            class="position-absolute bg-white rounded-circle d-flex align-items-center justify-content-center"
            style={{
              width: 40,
              height: 40,
              fontSize: 20,
              color: "#FFBC00",
              top: 20,
              left: 20,
            }}
          >
            <i class="bi bi-chevron-left"></i>
          </div>
        </Link>
        <img src={imgUrl(bannerImg)} class="w-100" />
      </div>
      <div class="container">
        <div
          class="card"
          style={{
            backgroundColor: "white",
            marginTop: "-8%",
            borderRadius: "1rem",
            marginBottom: 80,
          }}
        >
          <div class="row">
            <div class="col-12">
              <h3 style={{ margin: "1em" }}>All Job Lists</h3>
              {job ? (
                job.map((job) => {
                  console.log("date time", job.date_time);
                  return (
                    <div
                      class="card"
                      style={{ margin: "1em", border: "none" }}
                      onClick={() => handleClick(job.id)}
                    >
                      <div
                        class="card-body"
                        style={{
                          backgroundColor: "#fffdd0",
                          borderRadius: "1rem",
                        }}
                      >
                        <h5 class="card-title" style={{ color: "#ffbc00" }}>
                          {job.title}
                        </h5>
                        <p class="card-text text-black">
                          {Description(job.description).substring(0, 30)}...
                        </p>
                        <ul class="text-black">
                          <li>Benefits: {job.benefits}</li>
                          <li>Job Function: {job.job_function}</li>
                        </ul>
                        <div class="row text-black">
                          <div class="col-6">
                            <i class="bi bi-geo-alt-fill me-1"></i>
                            {job.company.location}
                          </div>
                          <div class="col-6">
                            <i class="bi bi-calendar3-week me-1"></i>
                            {job.date_time}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <p> - </p>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Joblist;
