import "../App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";

function About(props) {
  console.log("serviceDetailID", localStorage.getItem("serviceDetailID"));
  console.log("props", props);
  return (
    <div>
      <div class="col-12">
        <div
          class="text-light d-flex justify-content-between px-3 py-2"
          style={{ height: 50, backgroundColor: "#2D3134" }}
        >
          <Link to="/home">
            <i class="bi bi-chevron-left text-light"></i>
          </Link>
          <span>About App</span>
          <i class=""></i>
        </div>
      </div>
      <div class="container positon-relative">
        <div class="row">
          <div class="col-12">
            <Link to="/aboutdetail" style={{ textDecoration: "none" }}>
              <div
                class="card p-3 mt-3 mb-2 text-black"
                style={{
                  backgroundColor: "#F2F1F0",
                  borderRadius: "1rem",
                  border: "none",
                }}
              >
                <div class="row">
                  <div class="col-2">
                    <div
                      class="rounded-circle d-flex align-items-center justify-content-center"
                      style={{
                        width: 30,
                        height: 30,
                        fontSize: 13,
                        color: "#FFFFFF",
                        backgroundColor: "#FFBC00",
                      }}
                    >
                      <i class="bi bi-gem"></i>
                    </div>
                  </div>

                  <div class="col-10">
                    <div class="ms-3">
                      <small>About Application</small>
                      <br />
                      <small>ကုမ္ပဏီအကြောင်း သိကောင်းစရာ</small>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default About;
